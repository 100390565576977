import { firebaseAuth, getFirebaseMessaging } from 'boot/firebase-base';
import {
  getFCMTokenFromCache,
  saveFCMTokenInCache,
  setFcmWasTried,
  setSavedFcmTokensInDb,
} from 'src/repository/local-storage-repository';
import { None, Option, Some } from '@zwyssigly/functional';
import { isPWAEnabled } from 'src/services/remote-config-service';
import { getUserId } from 'src/services/firebase-auth';
import { addFcmTokenToDb } from 'src/repository/fcm-token-repository';
import { handleMessage } from 'src/services/message-handler-service';
import { FirebaseNotification, TrialStatus } from '@morsoftware/rad-core-commons';
import { logError, logInfo } from 'src/services/log-service';

/*
 * ------------------------------------------------
 * Service worker unregister related code.
 * ------------------------------------------------
 */

/**
 * Unregister all service workers and related caches.
 */
export const unregisterServiceWorkersAndClearCaches = async (): Promise<void> => {
  if (navigator.serviceWorker) {
    const registrations = await navigator.serviceWorker.getRegistrations();
    const unregisterPromises = registrations.map((registration) => registration.unregister());

    const allCaches = await caches.keys();
    const cacheDeletionPromises = allCaches.map((cache) => caches.delete(cache));

    await Promise.all([...unregisterPromises, ...cacheDeletionPromises])
      .catch(async (e) => {
        const message = `Error unregistering service worker and clearing caches for userId: ${getUserId()}`;
        logError(e, message);
      });
    setFcmWasTried(false);
  }
};

/**
 * Gets FCM token from cache if available, else gets it from firebase.messaging
 */
const getFCMToken = (): Promise<Option<string>> => {
  const fcmTokenFromCache = getFCMTokenFromCache();

  if (fcmTokenFromCache.isSome()) {
    return Promise.resolve(fcmTokenFromCache);
  }

  if (getFirebaseMessaging().isSome()) {
    return getFirebaseMessaging()
      .unwrap()
      .getToken({
        vapidKey: process.env.FCM_KEY,
      }).then(async (token) => {
        saveFCMTokenInCache(token);
        return Some(token);
      })
      .catch(async (e) => {
        if (e.message && e.message.includes('The notification permission was not granted')) {
          return None;
        }
        logError(e, 'Info: Could not get FCM token (service-worker-service)');
        return None;
      });
  }
  return Promise.resolve(None);
};

/**
 * Returns true if remote config has service worker disabled
 * OR the browser doesn't support service worker
 */
export const isServiceWorkerAllowed = (): boolean => isPWAEnabled()
  && navigator.serviceWorker
  && getFirebaseMessaging().isSome();

/**
 * Saves fcm token for the user in DB.
 * so that the user can be notified when new questions are added
 * or an app update is available.
 */
export const saveFcmTokenToDb = async (userId: string): Promise<void> => {
  const fcmTokenOpt = await getFCMToken();
  if (fcmTokenOpt.isNone()) {
    return Promise.resolve();
  }

  const fcmToken = fcmTokenOpt.unwrap();
  const token = await firebaseAuth?.currentUser?.getIdToken();
  if (token) {
    return addFcmTokenToDb(userId, fcmToken)
      .then(async () => {
        setSavedFcmTokensInDb(TrialStatus.SUCCESS);
        logInfo(`Added user: ${userId} to fcm_tokens table`);
      }).catch(async (e) => {
        setSavedFcmTokensInDb(TrialStatus.FAIL);
        const message = `Error adding user: ${userId} to fcm_tokens table`;
        logError(e, `${message} ${e.message}`);
      });
  }
  return Promise.resolve();
};

/**
 * Sets up infrastructure around FCM i.e.
 * a. Gets token; saves it into cache and sets up onMessage
 * b If no FCM token then sets up cache value for firebase messaging is not allowed
 */
export const setupFCM = async (): Promise<void> => {
  try {
    if (getFirebaseMessaging().isSome()) {
      const firebaseMessaging = getFirebaseMessaging().unwrap();
      firebaseMessaging.getToken({
        vapidKey: process.env.FCM_KEY,
      }).then(async (token) => {
        saveFCMTokenInCache(token);
        firebaseMessaging?.onMessage((payload) => {
          handleMessage(payload.notification as FirebaseNotification);
        });
      }).catch(async (e) => {
        setFcmWasTried(true);
        logError(e, 'Info: Could not get FCM token');
      });
    }
  } catch (e) {
    logError(e, 'Unknown error occurred while setting up FCM');
  } finally {
    setFcmWasTried(true);
  }
};
