import { isLogRocketEnabled } from 'src/services/remote-config-service';
import LogRocket from 'logrocket';
import { APP_VERSION } from '@morsoftware/rad-core-commons';
import { getUserId } from 'src/services/firebase-auth';
import { rollbar } from 'boot/rollbar-boot';

/**
 * Initialize log rocket.
 */
export const initializeLogRocket = (): void => {
  if (!process.env.DEV && isLogRocketEnabled()) {
    const dayOfMonth = new Date().getDate();
    // eslint-disable-next-line no-nested-ternary
    const appID = dayOfMonth <= 10
      ? '9tlrtu/rc'
      : dayOfMonth <= 20
        ? 'vpeeoz/radiologycored-dev'
        : 'ht9xtj/rad_core';
    LogRocket.init(appID, {
      release: APP_VERSION,
    });
    const userId = getUserId() || 'unsigned-user';
    LogRocket.identify(userId, {
      uid: userId,
      appVersion: APP_VERSION,
    });
  }
};

interface ICaptureOptions {
  tags?: {
    [tagName: string]: string | number | boolean,
  },
  extra?: {
    [tagName: string]: string | number | boolean,
  },
}

const getOptions = (errorMessage: string | null = null) : ICaptureOptions => ({
  tags: {
    userId: getUserId() || 'unsigned-user',
    version: APP_VERSION,
    dateTime: new Date().toISOString(),
    errorMessage: errorMessage ?? '',
  },
});

/**
 * Sends info log to LogRocket
 *
 * @param message info message
 */
export const logInfo = (
  message: string,
) : void => {
  if (!process.env.DEV) {
    rollbar?.info(message, getOptions());
  }
  LogRocket.info(message, getOptions());
};

export const captureException = (
  error: Error,
  options?: ICaptureOptions,
) : void => {
  if (!process.env.DEV) {
    LogRocket.captureException(error, options);
  }
};

/**
 * Sends error log to LogRocket
 *
 * @param error actual error object
 * @param errorMessage error message
 */
export const logError = (
  error: Error,
  errorMessage: string,
) : void => {
  if (!process.env.DEV) {
    rollbar?.error(error, getOptions(errorMessage));
    captureException(error, getOptions(errorMessage));
  }
};

/**
 * Sends error log to LogRocket
 *
 * @param message error message
 * @param options options
 */
export const logMessage = (
  message: string,
  options: Record<string, string | boolean>,
) : void => {
  if (!process.env.DEV) {
    LogRocket.captureMessage(message, {
      tags: {
        ...options,
      },
    });
  }
};
