import Rollbar from 'rollbar';
import { APP_VERSION } from '@morsoftware/rad-core-commons';

export const rollbar = new Rollbar();

rollbar.configure({
  accessToken: 'f33eefe6a68649dbba58b0a76a4e1b4f',
  captureUncaught: true,
  captureUnhandledRejections: true,
  addErrorContext: true,
  captureIp: true,
  codeVersion: APP_VERSION,
  enabled: !process.env.DEV,
  ignoredMessages: [
    /ChunkLoadError.*/,
    'ChunkLoadError.*',
    'Network Error',
    'Navigating to current location.*',
    'newestWorker is null',
    'Failed to update a ServiceWorker for scope.*',
  ],
});

// record a generic message and send it to Rollbar
rollbar?.info('Rollbar initialized!');

// "async" is optional;
// more info on params: https://quasar.dev/quasar-cli/boot-files
export default ({ Vue }) => {
  // Set the Rollbar instance in the Vue prototype
  // before creating the first Vue instance.
  // This ensures it is available in the same way for every
  // instance in your app.
  Vue.prototype.$rollbar = rollbar;
};
