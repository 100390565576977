import { FirebaseNotification } from '@morsoftware/rad-core-commons';
import { Notify } from 'quasar';
import {
  setSeenWhatsNewPageNotification,
} from 'src/repository/local-storage-repository';
import { sendDismissNotificationClicked, sendShowNotificationClicked } from 'src/services/analytics-service';
import VueRouter from 'vue-router';

const iosShareIcon = 'https://i.ibb.co/9tTXmks/share-ios-icon.png';

export const deleteAllCaches = () : Promise<boolean[]> => caches.keys()
  .then(
    (allCaches) => Promise.all(allCaches.map((cache) => caches.delete(cache))),
  );

/**
 * Show install ios message.
 */
export const showIosInstallMessage = () : void => {
  const img = `<img style="width: 0.9rem; height: 1.2rem; margin-right: 0.1rem; margin-left: 0.1rem;" src="${iosShareIcon}" alt="share icon">`;
  Notify.create({
    message: `<span>Install this app on your iPhone: tap icon ${img} and then select "Add to Home Screen"<span>`,
    icon: 'fiber_new',
    color: 'teal',
    html: true,
  });
};

export const showWhatsNewNotification = (
  router: VueRouter,
  openLeftDrawer: () => void,
) : void => {
  Notify.create({
    message: "We have added What's New page. Here you can checkout all our latest features!",
    icon: 'fiber_new',
    color: 'teal',
    actions: [
      {
        label: "Show what's new page",
        color: 'white',
        handler: () => {
          setSeenWhatsNewPageNotification();
          sendShowNotificationClicked();
          openLeftDrawer();
          router.push('/whats-new');
        },
      },
      {
        label: 'Dismiss',
        color: 'white',
        handler: () => {
          setSeenWhatsNewPageNotification();
          sendDismissNotificationClicked();
        },
      },
    ],
  });
};

export const handleVersionUpgrade = () : void => {
  Notify.create({
    message: 'New version available! Click to update.',
    icon: 'system_update',
    color: 'teal',
    actions: [{
      label: 'Update',
      color: 'white',
      handler: async () => {
        await deleteAllCaches();
        window.location.reload();
      },
    }, {
      label: 'Dismiss',
      color: 'white',
      handler: async () => {
        await deleteAllCaches();
      },
    }],
  });
};

function handleGenericMessage(
  message: string,
  icon: string,
) {
  Notify.create({
    message,
    icon,
    color: 'teal',
    actions: [{
      label: 'Dismiss',
      color: 'white',
    }],
  });
}

export const handleMessage = (message: FirebaseNotification) : void => {
  switch (message.title.toLowerCase()) {
    case 'new version':
      return handleVersionUpgrade();
    case 'new questions added':
      return handleGenericMessage('New questions added!', 'fiber_new');
    default:
      return handleGenericMessage(message.body, 'eco');
  }
};
