import { getDb } from 'boot/firebase-base';
import { APP_VERSION, FCM_TOKEN_COLLECTION, FCM_TOKENS_UUID } from '@morsoftware/rad-core-commons';
import { logError } from 'src/services/log-service';

/**
 * Add this user's FCM token to DB.
 *
 * @param userId userId
 * @param fcmToken fcmToken
 */
export const addFcmTokenToDb = async (
  userId: string,
  fcmToken: string,
): Promise<void> => {
  try {
    await getDb()
      .collection(FCM_TOKEN_COLLECTION)
      .doc(FCM_TOKENS_UUID)
      .set({
        fcmTokens: {
          [userId]: fcmToken,
        },
        updatedDateTime: new Date(),
      }, {
        merge: true,
      });
    return Promise.resolve();
  } catch (e) {
    logError(e, `Error adding fcmToken to db: ${e.message} for user: ${userId}, version: ${APP_VERSION}, fcmToken: ${fcmToken}`);
    return Promise.resolve();
  }
};
