import { ref } from '@vue/composition-api';

export enum SubscriptionState {
  NOT_SUBSCRIBED= 'NOT_SUBSCRIBED',
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
}

export const PAYWALL_STATE = ref(false);
export const SUBSCRIPTION_STATE = ref('NOT_SUBSCRIBED');
export const SHOW_SUBSCRIPTION_DIALOG_STATE = ref(false);

export function useSubscriptionState() {
  function setPaywallState(state: boolean) {
    PAYWALL_STATE.value = state;
  }

  function showSubscriptionRequestDialog(show: boolean) {
    SHOW_SUBSCRIPTION_DIALOG_STATE.value = show;
  }

  function setSubscriptionState(hasSubscribed: boolean, expired: boolean) {
    if (!hasSubscribed) {
      SUBSCRIPTION_STATE.value = SubscriptionState.NOT_SUBSCRIBED;
    } else {
      SUBSCRIPTION_STATE.value = expired ? SubscriptionState.EXPIRED : SubscriptionState.ACTIVE;
    }
    showSubscriptionRequestDialog(SUBSCRIPTION_STATE.value !== SubscriptionState.ACTIVE);
  }

  return {
    setPaywallState,
    setSubscriptionState,
    showSubscriptionRequestDialog,
  };
}
