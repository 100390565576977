import { getDb } from 'boot/firebase-base';
import {
  Quasar, APP_VERSION, EMAILS, LogLevel,
} from '@morsoftware/rad-core-commons';

import { getUserEmail, getUserId } from 'src/services/firebase-auth';
import { getLocalStorage, setLocalStorage } from 'src/repository/local-storage-repository';
import axios from 'axios';
import { logError } from 'src/services/log-service';
import { Dialog } from 'quasar';

/**
 * Show error dialog to the user.
 * @param {string} errorMessage error message for the user.
 */
function showErrorDialog(errorMessage: string) : void {
  Dialog.create({
    dark: true,
    title: 'Error',
    message: errorMessage,
    html: false,
    cancel: false,
  });
}

/**
 * Creates a new record in emails table.
 *
 * @param {string} userId user id
 * @param {string} date current date
 * @param {LogLevel} logLevel logLevel
 * @param {string} userErrorMessage message for the user
 * @param {string} appErrorMessage real error with more detail
 */
export function createRecordInEmailsTable(
  userId: string | undefined,
  date: string,
  logLevel: LogLevel,
  userErrorMessage: string,
  appErrorMessage: string = userErrorMessage,
) : Promise<void> {
  return getDb()
    .collection(EMAILS)
    .doc(`${APP_VERSION}:${logLevel}:${date}`)
    .set({
      to: 'contact@morsoftwaresolutions.com',
      userId,
      version: APP_VERSION,
      level: logLevel,
      date,
      message: {
        subject: `${userErrorMessage}`,
        text: `${userId} | ${logLevel} | ${date} | ${appErrorMessage}`,
      },
    });
}

/**
 * Sends notification to the author.
 *
 * @param {string} subject subject of the email
 * @param {string} body feedback content
 */
export async function sendNotification(
  subject: string,
  body: string,
): Promise<void> {
  const url = process.env.SLACK_URL
    ?? 'https://us-west3-radiology-cored.cloudfunctions.net/send-notification';
  const isTest = !!process.env.DEV;
  try {
    await axios.post(
      url,
      {
        isTest,
        subject,
        body: `${body}: email: ${getUserEmail()}`,
      },
      {
        headers: {
          Accept: 'application/json',
        },
      },
    );
    return Promise.resolve();
  } catch (e) {
    logError(e, `Error sending slack message: userId: ${getUserId()}, version: ${APP_VERSION}`);
    return Promise.resolve();
  }
}

/**
 * Creates a log record in the database.
 * This will trigger an email to the author of the app.
 *
 * @param {string} userErrorMessage error message shown to user
 * @param {string} appErrorMessage error message for the author of the app
 * @param {LogLevel} errorLevel error level
 */
export function logRecordToDb(
  userErrorMessage: string,
  appErrorMessage: string,
  errorLevel: LogLevel,
): Promise<boolean> {
  const userId = getUserId();
  const date = new Date().toISOString();
  const updatedAppErrorMessage = `${appErrorMessage} user: ${userId}, date: ${date}, level: ${errorLevel}, version: ${APP_VERSION}`;
  return Promise.all([
    sendNotification(
      userErrorMessage,
      updatedAppErrorMessage,
    ),
    createRecordInEmailsTable(userId, date, errorLevel, userErrorMessage, appErrorMessage),
  ]).then(() => true)
    .catch((err) => {
      const errors: string[] = getLocalStorage('errors') as string[] || [];
      setLocalStorage('errors', [...errors, err.message]);
      return false;
    });
}

/**
 * Shows an error popup to the user and stores the error record
 * in the database which will trigger an email to the author of the app.
 *
 * @param {string} userErrorMessage error message to be shown to the user.
 * @param {string} errorMessage error message for the author of the app.
 * @param {LogLevel} errorLevel error level.
 * @param {Quasar} q quasar object that has dialog on it.
 */
export const notifyAuthor = (
  userErrorMessage: string,
  errorMessage: string,
  errorLevel: LogLevel,
  // TODO: Clean this up (created 9/22/2021)
  q: Quasar | null = null,
): Promise<boolean> => {
  showErrorDialog(userErrorMessage);
  return logRecordToDb(userErrorMessage, errorMessage, errorLevel);
};
