/**
 * Returns true if value is null or undefined.
 * @param {val:any} val incoming value to be checked
 * @returns {boolean} true if value is null or undefined.
 */
export function isNullOrUndefined(val: unknown) : boolean {
  return val === null || val === undefined;
}

export function isNotNullOrUndefined(val: unknown) : boolean {
  return !isNullOrUndefined(val);
}

export function isArrayNotEmpty(val: Array<unknown>) : boolean {
  return Array.isArray(val) && val.length > 0;
}

export function diffInSeconds(endDate: Date, startDate: Date) : number {
  return endDate.getSeconds() - startDate.getSeconds();
}
