import { register } from 'register-service-worker';

// The ready(), registered(), cached(), updatefound() and updated()
// events passes a ServiceWorkerRegistration instance in their arguments.
// ServiceWorkerRegistration: https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerRegistration

register(process.env.SERVICE_WORKER_FILE, {
  // The registrationOptions object will be passed as the second argument
  // to ServiceWorkerContainer.register()
  // https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerContainer/register#Parameter

  // registrationOptions: { scope: './' },

  /* eslint-disable no-console */

  ready(/* registration */) {
    // console.log(`-------- Service worker is active: ${JSON.stringify(registration, null, 2)}`);
  },

  registered(registration) {
    setInterval(async () => {
      await registration.update();
    }, 1000 * 60 * 60);
  },

  cached(/* registration */) {
    // console.log('-------- Content has been cached for offline use.', registration);
  },

  updatefound(/* registration */) {
    // console.log('-------- New content is downloading.', registration);
  },

  updated(registration) {
    // console.log('-------- New content is available; please refresh.', registration);
    document.dispatchEvent(
      new CustomEvent('swUpdated', { detail: registration }),
    );
  },

  offline() {
    // console.log('-------- No internet connection found. App is running in offline mode.');
  },

  error(/* err */) {
    // console.error('-------- Error during service worker registration:', err);
  },
});
