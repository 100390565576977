<template>
  <div id="q-app" class="bg-dark rad-page">
    <router-view/>
  </div>
</template>

<script>
import 'boot/init-app';
import { setupForDevEnv, remoteConfig } from 'boot/firebase-base';
import {
  isLoggedIn, logoutUser, loginUser, getCurrentUserId, isAppLoaded,
  handleAuthStateChange,
} from 'src/services/auth-service';
import { unregisterServiceWorkersAndClearCaches } from 'src/services/service-worker-service';
import { deleteAllImages } from 'src/repository/image-repository';
import { addPaypalOrderToUser, getUserInfo } from 'src/repository/user-repository';
import { useCardState } from 'src/state/CardsState';

export default {
  beforeCreate() {
    if (window.Cypress) {
      const { setCards } = useCardState();
      // only available during E2E tests
      window.cache = {
        unregisterServiceWorkersAndClearCaches,
      };
      window.storage = {
        deleteAllImages,
        remoteConfig,
      };
      window.payPal = {
        addPaypalOrderToUser,
      };
      window.auth = {
        unregisterServiceWorkersAndClearCaches,
        isLoggedIn,
        loginUser,
        logoutUser,
        getCurrentUserId,
        isAppLoaded,
      };
      window.user = {
        getUserInfo,
      };
      window.cards = {
        setCards,
      };
    }
    handleAuthStateChange(this.$router);
    setupForDevEnv();
  },
};
</script>
