import { getStorageRef } from 'boot/firebase-base';
import { Err, Ok, Result } from '@zwyssigly/functional';
import {
  FirebaseStorageError, ImageRetrievalResult, LogLevel, RCError,
  QuestionMetadataForSessionRecord, APP_VERSION,
} from '@morsoftware/rad-core-commons';
import { logRecordToDb, sendNotification } from 'src/services/notify-author';
import { getUserId } from 'src/services/firebase-auth';
import { sleep } from 'src/util/math-util';
import { getResizedImageFileName } from 'src/util/string-util';
import { logMessage } from 'src/services/log-service';

type ErrorMap = {
  [errorCode: string]: string,
}

const errorMap: ErrorMap = {
  'storage/object-not-found': 'Image not found',
  'storage/unauthorized': 'You do not have access to this image. We are looking into it.',
  'storage/canceled': 'User canceled the upload',
  'storage/unknown': 'Unknown error',
};

const handleError = (
  context: string,
  imageName: string,
  err: FirebaseStorageError,
): RCError => {
  const errorMessage = errorMap[err.code as string] ?? `Unknown system error while getting image: ${imageName}. This could related to internet connectivity. Please wait a bit and try again.`;
  const appErrorMessage = `${err.message}: context: ${context}`;
  return new RCError(appErrorMessage, errorMessage);
};

export const formulateImageUrl = async (
  context: string,
  imageName: string,
  cardImage = false,
  retryCount = process.env.DEV ? 0 : 3,
): Promise<Result<string, RCError>> => getStorageRef()
  .child(imageName)
  .getDownloadURL()
  .then((url) => Ok(url))
  .catch(async (error) => {
    if (retryCount > 0) {
      await sleep(600);
      return formulateImageUrl(context, imageName, cardImage, retryCount - 1);
    }
    if (retryCount === 0) {
      // For some reason during cypress test, the Firebase resizer function is not working
      // hence doing this. This is also a great back up option if image resizer fails in Prod
      // in which case just get the original image i.e. remove _400x400 from image name.
      return formulateImageUrl(
        context,
        cardImage
          ? imageName.replace('_400x400', '')
          : imageName,
        cardImage,
        retryCount - 1,
      );
    }
    const rcError = handleError(context, imageName, error);
    logMessage(rcError.userErrorMessage, {
      context,
      imageName,
      cardImage,
      userId: getUserId() || 'unsigned-user',
      version: APP_VERSION,
      dateTime: new Date().toISOString(),
      errorMessage: rcError.appErrorMessage,
    });
    await logRecordToDb(rcError.userErrorMessage, rcError.appErrorMessage, LogLevel.ERROR);
    return Err(rcError);
  });

/**
 * Deletes all the cars for a given user.
 * Primarily is present for IT tests only.
 */
export const deleteAllImages = async (): Promise<void> => getStorageRef()
  .child(`cards/${getUserId()}`)
  .listAll()
  .then(async (res) => {
    await Promise.all(res.items.map((image) => image.delete()));
  });

/**
 * Delete card image.
 */
export const deleteCardImage = async (
  imageName: string,
): Promise<Result<string, RCError>> => {
  // Only images in /flashcards folder are allowed to be deleted
  if (imageName.startsWith('cards')) {
    return getStorageRef()
      .child(getResizedImageFileName(imageName))
      .delete()
      .then(() => Ok('success'))
      .catch(async (error) => {
        const rcError = handleError('deleteCardImage', imageName, error);
        await sendNotification(rcError.userErrorMessage, rcError.appErrorMessage);
        return Err(rcError);
      });
  }

  return Promise.resolve(Ok('success'));
};

const imageUrlRegex = new RegExp(/.*(question_\d{1,3}_\d\.png).*/);

export const getImageNamesFromUrls = (
  imageUrls: string[] | null | undefined,
): string[] => (
  imageUrls && imageUrls.length > 0
    ? imageUrls.map((imageUrl) => {
      const results = imageUrlRegex.exec(imageUrl);
      return (results && results.length >= 2) ? results[1] : imageUrl;
    })
    : []
);

export const updateWithFormulatedImageUrlsForReviewSession = async (
  questionMetadata: QuestionMetadataForSessionRecord,
): Promise<void> => {
  const imageRetrievalResults: ImageRetrievalResult = {
    hasImage: (questionMetadata.imageUrls ?? []).length > 0,
    urls: [],
    appErrorMessages: [],
    userErrorMessages: [],
  };

  const formulateImageUrlPromises = getImageNamesFromUrls(questionMetadata.imageUrls)
    .map((imageUrl: string) => {
      if (imageUrl.startsWith('http')) {
        imageRetrievalResults.urls.push(imageUrl);
        return Promise.resolve();
      }
      return formulateImageUrl(
        'updateWithFormulatedImageUrlsForReviewSession',
        imageUrl,
      )
        .then((result) => {
          if (result.isErr()) {
            const rcError = result.unwrapErr();
            imageRetrievalResults.appErrorMessages.push(rcError.appErrorMessage);
            imageRetrievalResults.userErrorMessages.push(rcError.userErrorMessage);
          } else {
            imageRetrievalResults.urls.push(result.unwrap());
          }
        });
    });

  await Promise.all(formulateImageUrlPromises);
  questionMetadata.imageRetrievalResults = imageRetrievalResults;
};
