import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/analytics';

import 'firebase/firestore';

// Add the Performance Monitoring library
import 'firebase/performance';
import 'firebase/remote-config';
import 'firebase/messaging';
import { None, Option, Some } from '@zwyssigly/functional';
import { hoursInMillis } from 'src/util/date-util';
import { rollbar } from 'boot/rollbar-boot';

const firebaseConfig = {
  apiKey: process.env.API_KEY,
  authDomain: process.env.AUTH_DOMAIN,
  databaseURL: process.env.DB_URL,
  projectId: process.env.PROJECT_ID,
  storageBucket: process.env.STORAGE_BUCKET,
  messagingSenderId: process.env.MSG_SENDER_ID,
  appId: process.env.API_ID,
};

// Initialize Firebase
export const firebaseApp = firebase.initializeApp(firebaseConfig);
export const firebaseAuth = firebase.auth();
// Initialize Performance Monitoring and get a reference to the service
export const performance = firebase.performance();

export const minimumFetchIntervalMillis = process.env.DEV ? 1_000 : hoursInMillis(5);

export const remoteConfig = firebase.remoteConfig();
remoteConfig.settings = {
  fetchTimeoutMillis: process.env.DEV ? 1000 : 60000,
  minimumFetchIntervalMillis,
};

export const analytics = firebase.analytics();
firebase.analytics
  .isSupported()
  .then((isSupported) => {
    if (isSupported) {
      analytics.setUserProperties({ app_version: '1.0.0' });
      if (!process.env.DEV) {
        rollbar?.info('googleAnalytics is supported!');
      }
    } else if (!process.env.DEV) {
      rollbar?.info('googleAnalytics is not supported!');
    }
  }).catch((error) => {
    rollbar?.info(`Error occurred checking if google analytics is supported: ${error.message}`);
  });

/**
 * Firebase messaging section.
 */
export const getFirebaseMessaging = (): Option<firebase.messaging.Messaging> => (
  navigator.serviceWorker
  && firebase.messaging.isSupported()
    ? Some(firebase.messaging())
    : None);

// Creating a non-const version so that we can
// set it in test via setDb
let firebaseDB = firebase.firestore();
const storage = firebase.storage();
export const increment = (
  inc: number,
): firebase.firestore.FieldValue => firebase.firestore.FieldValue.increment(inc);

export function setDb(inDb: firebase.firestore.Firestore): void {
  firebaseDB = inDb;
}

export const getDb = (): firebase.firestore.Firestore => firebaseDB;

export function getStorageRef(): firebase.storage.Reference {
  return storage.ref('');
}

export function getStorage(): firebase.storage.Storage {
  return storage;
}

// This allows us to test against Firebase Emulators
// when running it locally.
// Anytime we start in local, it won't go to real Firestore.
export function setupForDevEnv(): void {
  if (process.env.DEV) {
    firebaseDB.settings({
      host: 'localhost:8090',
      ssl: false,
      experimentalForceLongPolling: true, // added because of https://github.com/cypress-io/cypress/issues/2374
    });
  }
}
