import { ref, Ref, UnwrapRef } from '@vue/composition-api';
import { Card } from '@morsoftware/rad-core-commons';
import { getUserId } from 'src/services/firebase-auth';
import { eventBus } from 'boot/init-app';

export const cardsState: Ref<UnwrapRef<Card[]>> = ref<Card[]>([]);
export const cardState: Ref<UnwrapRef<Card>> = ref<Card>({
  createdBy: '',
  createdDateTime: new Date(),
  definition: '',
  id: '',
  imageUrls: [],
  fullImageUrls: [],
  imageFetchError: false,
  starred: {},
  tags: [],
  isPrivate: false,
  term: '',
  updatedDateTime: new Date(),
});
export const cardIndexState: Ref<UnwrapRef<number>> = ref<number>(-1);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useCardState() {
  function setCards(cards: Card[], loadMore = false) {
    if (loadMore) {
      cardsState.value = cardsState.value.concat(cards);
    } else {
      cardsState.value = cards;
      // eslint-disable-next-line prefer-destructuring
      cardState.value = cards[0];
      cardIndexState.value = 0;
    }
  }

  function hasCards(): boolean {
    return cardsState.value.length > 0;
  }

  function getCardIndexForUI() {
    return `${cardIndexState.value + 1} / ${cardsState.value.length}`;
  }

  function getCurrentCard() {
    cardState.value = cardsState.value[cardIndexState.value];
    return cardState.value;
  }

  function getCurrentCardIndex() {
    return cardIndexState.value;
  }

  function isSecondLastCard() {
    return cardIndexState.value >= cardsState.value.length - 2;
  }

  function getNextCard() {
    cardIndexState.value += 1;
    cardState.value = cardsState.value[cardIndexState.value];

    if (isSecondLastCard()) {
      eventBus.$emit('getMoreCardsEvent');
    }
  }

  function getPreviousCard() {
    cardIndexState.value -= 1;
    cardState.value = cardsState.value[cardIndexState.value];
  }

  function getLastCard() {
    cardIndexState.value = cardsState.value.length - 1;
    cardState.value = cardsState.value[cardIndexState.value];
  }

  function isCardEditable() {
    return getCurrentCard()?.createdBy === getUserId();
  }

  function isFirstCard() {
    return cardIndexState.value === 0;
  }

  function isLastCard() {
    return cardIndexState.value >= cardsState.value.length - 1;
  }

  function addCard(card: Card): void {
    cardsState.value = [card, ...cardsState.value];
    // eslint-disable-next-line prefer-destructuring
    cardState.value = cardsState.value[0];
    cardIndexState.value = 0;
  }

  function deleteCard(): void {
    cardsState.value.splice(cardIndexState.value, 1);
    if (isLastCard()) {
      getLastCard();
    }
  }

  function updateCard(eventCard: Record<string, string | string[] | boolean | null>)
    : void {
    const cardIndex = cardIndexState.value;
    const card = cardsState.value[cardIndex];
    cardsState.value[cardIndex] = {
      ...card,
      ...eventCard,
    };
    cardState.value = cardsState.value[cardIndex];
  }

  function isCardStarred(): boolean {
    const userId = getUserId();
    return userId ? cardState?.value?.starred?.[userId] ?? false : false;
  }

  function starCard(starred: boolean): void {
    const userId = getUserId();
    if (userId) {
      if (cardState.value.starred) {
        cardState.value.starred[userId] = starred;
      } else {
        cardState.value.starred = {
          [userId]: starred,
        };
      }
    }
  }

  return {
    setCards,
    hasCards,
    starCard,
    isCardStarred,
    addCard,
    deleteCard,
    updateCard,

    getCardIndexForUI,
    getCurrentCard,
    getCurrentCardIndex,
    getNextCard,
    getPreviousCard,
    isCardEditable,
    isFirstCard,
    isLastCard,
  };
}
