/**
 * ###################################################################
 * ######################## HACK ALERT ###############################
 * ###################################################################
 *
 * This needs to happen right BEFORE App.vue is loaded.
 * Hence named it init-app.js and source it in App.vue
 * We need to do this so as to be able to use Composition API based
 * JS files in App.vue
 *
 * ###################################################################
 * ###################################################################
 * ###################################################################
 */
import Vue from 'vue';
import VueCompositionApi, { ref } from '@vue/composition-api';

Vue.use(VueCompositionApi);
export const eventBus = new Vue();
export const INSTALL_APP_PROMPT = ref(null);
