import { LocalStorage } from 'quasar';
import { None, Option, Some } from '@zwyssigly/functional';
import {
  PayPalSubscription, QuestionStatDoc, SubscriptionOrder, TrialStatus,
  PaypalOrder,
} from '@morsoftware/rad-core-commons';
import { addDaysToDate, getDaysFromTime } from 'src/util/date-util';

/**
 * Created this file so as to easily mock interaction with LocalStorage plugin.
 */
export const getLocalStorage = (key: string): unknown => LocalStorage.getItem(key);

export function getLocalStorageString(key: string): string {
  return LocalStorage.getItem(key) as string;
}

export function setLocalStorage(
  key: string,
  value: string | number | Record<string, unknown> | undefined | string[] | boolean,
): void {
  if (value !== null && value !== undefined) {
    LocalStorage.set(key, value);
  }
}

export interface CacheItemWithTtl {
  expiry: number,
  value: Record<string, unknown>
}

export const setWithExpiry = (
  key: string,
  value: Record<string, unknown>
    | SubscriptionOrder
    | PaypalOrder
    | PayPalSubscription,
  ttl: number,
) : void => {
  const now = new Date();

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value,
    expiry: now.getTime() + ttl,
  };
  LocalStorage.set(key, item);
};

export const getWithExpiry = (key: string): Option<Record<string, unknown>> => {
  const item = LocalStorage.getItem(key) as CacheItemWithTtl;
  if (!item) {
    return None;
  }
  const now = new Date();
  if (now.getTime() > item.expiry) {
    LocalStorage.remove(key);
    return None;
  }
  return Some(item.value);
};

export const setSeenWhatsNewPageNotification = () : void => {
  setLocalStorage('seenWhatsNewPageNotification', true);
};

export const hasSeenWhatsNewPageNotification = () : boolean => (
  getLocalStorage('seenWhatsNewPageNotification') ?? false
) as boolean;

const QUESTION_STATS_KEY = 'questionStats';

export const storeQuestionStatsInCache = (questionStats: QuestionStatDoc) : void => {
  setLocalStorage(QUESTION_STATS_KEY, questionStats);
};

export const getQuestionStatsFromCache = () : Option<QuestionStatDoc> => (
  LocalStorage.has(QUESTION_STATS_KEY)
    ? Some(getLocalStorage(QUESTION_STATS_KEY) as QuestionStatDoc)
    : None);

const FCM_TOKEN_CACHE_KEY = 'fcmToken';
const SAVED_FCM_TOKEN_IN_DB_CACHE_KEY = 'SavedFcmTokensInDb';
const FCM_SETUP_TRIED = 'wasFCMSetupTried';
const NEXT_VERSION_NOTIFICATION_DATE = 'NextVersionNotificationDate';
const SHOWN_IOS_INSTALL_MESSAGE = 'ShownIosInstallMessage';
const LAST_REMOTE_CONFIG_LOAD_TIME = 'LastRemoteConfigLoadTime';

const getBooleanCacheValue = (cacheKey: string): boolean => LocalStorage.has(cacheKey)
  && LocalStorage.getItem(cacheKey) as boolean;

const getTrialStatusCacheValue = (cacheKey: string): TrialStatus => (LocalStorage.has(cacheKey)
  ? LocalStorage.getItem(cacheKey) as TrialStatus
  : TrialStatus.NOT_TRIED);

export const saveFCMTokenInCache = (token: string): void => {
  setLocalStorage(FCM_TOKEN_CACHE_KEY, token);
};

export const getFCMTokenFromCache = (): Option<string> => (LocalStorage.has('token')
  ? Some(LocalStorage.getItem(FCM_TOKEN_CACHE_KEY) as string)
  : None);

export const setSavedFcmTokensInDb = (trialStatus: TrialStatus): void => {
  setLocalStorage(SAVED_FCM_TOKEN_IN_DB_CACHE_KEY, trialStatus);
};

export const alreadySavedFcmTokensInDb = (): boolean => getTrialStatusCacheValue(
  SAVED_FCM_TOKEN_IN_DB_CACHE_KEY,
) !== TrialStatus.NOT_TRIED;

export const setFcmWasTried = (isFcmSetup: boolean): void => {
  setLocalStorage(FCM_SETUP_TRIED, isFcmSetup);
};

export const wasFCMSetupTried = () : boolean => getBooleanCacheValue(FCM_SETUP_TRIED);

export const setNextVersionNotificationDate = () : void => setLocalStorage(
  NEXT_VERSION_NOTIFICATION_DATE, addDaysToDate(1),
);

export const shouldShowNextVersionNotification = () : boolean => new Date().getTime()
  >= getDaysFromTime(LocalStorage.getItem(NEXT_VERSION_NOTIFICATION_DATE));

export const shownIosInstallMessage = () : boolean => getBooleanCacheValue(
  SHOWN_IOS_INSTALL_MESSAGE,
);

export const setShownIosInstallMessage = () : void => {
  setLocalStorage(SHOWN_IOS_INSTALL_MESSAGE, true);
};

export const setLastRemoteConfigLoadTime = (): void => setLocalStorage(
  LAST_REMOTE_CONFIG_LOAD_TIME, new Date().getTime(),
);

export const getLastRemoteConfigFetchTime = () : number => LocalStorage.getItem(
  LAST_REMOTE_CONFIG_LOAD_TIME,
) as number ?? -1;
