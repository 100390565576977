import { analytics } from 'boot/firebase-base';
import { CreateSessionType } from 'src/services/session-options-service';
import { shouldSendAnalyticsEvents } from 'src/services/remote-config-service';
import { APP_VERSION } from '@morsoftware/rad-core-commons';

export const sendNewSessionEvent = (createsSessionType: CreateSessionType): void => {
  if (shouldSendAnalyticsEvents()) {
    analytics.logEvent('newSessionEvent', {
      bookmarkedQuestion: createsSessionType.includeBookmarkedQuestion,
      failedQuestion: createsSessionType.includeFailedQuestion,
      sessionType: createsSessionType.sessionType,
      appVersion: APP_VERSION,
    });
  }
};

export const sendEventToAnalytics = (
  eventName: string,
  eventData: Record<string, unknown> | null = null,
): void => {
  if (shouldSendAnalyticsEvents()) {
    if (eventData) {
      analytics.logEvent(eventName, eventData);
    } else {
      analytics.logEvent(eventName);
    }
  }
};

export const sendCompletedSessionEvent = (): void => sendEventToAnalytics(
  'completedSessionEvent',
);

export const sendWhatsNewEvent = (): void => sendEventToAnalytics('rc_whats_new');
export const sendStatisticsEvent = (): void => sendEventToAnalytics('rc_statistics_event');
export const sendLoginEvent = (): void => sendEventToAnalytics('loginEvent');
export const sendReviewSessionEvent = (): void => sendEventToAnalytics('reviewSessionEvent');
export const sendShowNotificationClicked = (): void => sendEventToAnalytics('rc_show_notification_clicked');
export const sendAppInstalledEvent = (): void => sendEventToAnalytics('rc_app_installed');
export const sendDismissNotificationClicked = (): void => sendEventToAnalytics('rc_dismiss_notification_clicked');
export const sendCardPageEvent = (): void => sendEventToAnalytics('rc_card_page');
export const sendRetakeQuestions = (): void => sendEventToAnalytics('rc_retake_questions');
export const sendDisplayModeEvent = (displayMode: string): void => sendEventToAnalytics(
  'rc_display_mode',
  {
    displayMode,
  },
);
