/**
 * Adds number of days to current date
 *
 * @param days number of days
 */
export const addDaysToDate = (days: number) : number => {
  const date = new Date();
  date.setDate(date.getDate() + days);
  return date.getTime();
};

/**
 * Get the time for the given date or get current date time.
 *
 * @param time
 */
export const getDaysFromTime = (time: number | null) : number => (time || new Date().getTime());

/**
 * Returns given number of hours in millseconds.
 *
 * @param {number} hours number of hours.
 */
export const hoursInMillis = (hours: number) : number => hours * 60 * 60 * 1000;
