import { CreateSessionType } from 'src/services/session-options-service';
import { None, Option } from '@zwyssigly/functional';
import {
  SubscriptionOrder, UserInfoDbRecordType, PaypalOrder,
  USER_SPECIFIC_QUESTION_LIMIT_NOT_SET, UserQuestionSubjectStat,
} from '@morsoftware/rad-core-commons';
import { getMaxQuestionsAllowed, isPaywallOn } from 'src/services/remote-config-service';

export class UserInfo implements UserInfoDbRecordType {
  userId: string;

  markedQuestions: Array<number>;

  failedQuestions: Array<number>;

  skippedQuestions: Array<number>;

  lastQuestionId: number;

  questionsAllowed: number;

  questionsAttempted: number;

  createdDate: Date;

  subscription: SubscriptionOrder;

  payPalOrders: PaypalOrder[];

  payPalOrder: PaypalOrder | null;

  subscriptionExpiryDate: Date | null;

  hasSubscribed: boolean;

  hasSubscriptionEnded: boolean;

  private readonly hasQuestions: boolean;

  subjectStats: UserQuestionSubjectStat | null | undefined;

  constructor(userInfoFromDb: UserInfoDbRecordType) {
    this.userId = userInfoFromDb.userId;
    this.markedQuestions = userInfoFromDb.markedQuestions;
    this.failedQuestions = userInfoFromDb.failedQuestions;
    this.skippedQuestions = userInfoFromDb.skippedQuestions;
    this.lastQuestionId = userInfoFromDb.lastQuestionId;
    this.questionsAllowed = userInfoFromDb.questionsAllowed;
    this.questionsAttempted = userInfoFromDb.questionsAttempted;
    this.createdDate = userInfoFromDb.createdDate;
    this.subjectStats = userInfoFromDb.subjectStats;
    this.hasQuestions = this.markedQuestions?.length
      + this.failedQuestions?.length
      + this.skippedQuestions?.length
      > 0;
    this.subscription = userInfoFromDb.subscription;
    this.payPalOrders = userInfoFromDb.payPalOrders;
    this.hasSubscribed = userInfoFromDb.payPalOrders && userInfoFromDb.payPalOrders.length > 0;
    this.payPalOrder = this.hasSubscribed
      ? userInfoFromDb.payPalOrders[userInfoFromDb.payPalOrders.length - 1]
      : null;
    this.subscriptionExpiryDate = this.payPalOrder && this.payPalOrder.expiryDateTime
      ? new Date(this.payPalOrder.expiryDateTime) : null;
    this.hasSubscriptionEnded = this.subscriptionExpiryDate != null
      && new Date().getTime() > this.subscriptionExpiryDate.getTime();
  }

  getQuestionsForSession(createsSessionType: CreateSessionType) : Option<number[]> {
    if (!this.hasQuestions) {
      return None;
    }
    const questionIds = new Set<number>();
    if (createsSessionType.includeBookmarkedQuestion) {
      this?.markedQuestions.forEach(questionIds.add, questionIds);
    }
    if (createsSessionType.includeFailedQuestion) {
      this?.failedQuestions.forEach(questionIds.add, questionIds);
    }
    this?.skippedQuestions.forEach(questionIds.add, questionIds);
    return Option([...questionIds]);
  }

  getQuestionsAllowed() : number {
    return (this.questionsAllowed === USER_SPECIFIC_QUESTION_LIMIT_NOT_SET
      ? getMaxQuestionsAllowed()
      : this.questionsAllowed);
  }

  showSubscribeDialog() : boolean {
    if (isPaywallOn()) {
      return this.questionsAttempted >= this.getQuestionsAllowed() || this.hasSubscriptionEnded;
    }
    return false;
  }
}
