import firebase from 'firebase/app';

export function collectDataFromSnapshot<T>(dbRecord: firebase.firestore.QuerySnapshot): Array<T> {
  const rows: Array<T> = [];
  dbRecord.forEach((
    doc: firebase.firestore.QueryDocumentSnapshot,
  ) => {
    const data = { ...doc.data() };
    data.id = doc.id;
    rows.push(data as T);
  });
  return rows;
}
