import { ref } from '@vue/composition-api';
import { firebaseAuth } from 'boot/firebase-base';
import { alreadySavedFcmTokensInDb, setLocalStorage } from 'src/repository/local-storage-repository';
import { Loading } from 'quasar';
import { showErrorMessage } from 'src/functions/function-show-error-message';
import { createUserRecordInDb, getUserInfo } from 'src/repository/user-repository';
import firebase from 'firebase/app';
import { isNotNullOrUndefined } from 'src/functions/util';
import VueRouter from 'vue-router';
import { logRecordToDb } from 'src/services/notify-author';
import { LogLevel } from '@morsoftware/rad-core-commons';
import { sendLoginEvent } from 'src/services/analytics-service';
import { isServiceWorkerAllowed, saveFcmTokenToDb } from 'src/services/service-worker-service';
import { rollbar } from 'boot/rollbar-boot';

const state = ref(false);
let appLoadedState = false;

interface AuthPayload {
  email: string,
  password: string,
}

type FirebaseFunc = (email: string, password: string) => Promise<firebase.auth.UserCredential>;

export const isAppLoaded = (): boolean => appLoadedState;

const setLoggedIn = (loggedIn: boolean) => {
  state.value = loggedIn;
};

export const isLoggedIn = (): boolean => state.value;

export function isUserLoggedIn(): boolean {
  return isNotNullOrUndefined(firebaseAuth.currentUser?.uid)
    && firebaseAuth.currentUser?.uid !== '';
}

export const authFunc = (
  payload: AuthPayload,
  firebaseFunc: FirebaseFunc,
): void => {
  Loading.show();
  firebaseFunc(payload.email, payload.password)
    .then(() => {
      Loading.hide();
    })
    .catch((error) => {
      Loading.hide();
      showErrorMessage(error.message);
    });
};

export const registerUser = async (payload: AuthPayload): Promise<void> => {
  Loading.show();
  await firebaseAuth.createUserWithEmailAndPassword(payload.email, payload.password)
    .then(async (user) => {
      Loading.hide();
      const appErrorMessage = `New user registered: ${payload.email}`;
      // eslint-disable-next-line no-return-await
      return await Promise.all([
        createUserRecordInDb(user.user?.uid, user.user?.email),
        logRecordToDb(appErrorMessage, appErrorMessage, LogLevel.INFO),
      ]);
    })
    .catch((error) => {
      Loading.hide();
      showErrorMessage(error.message);
    });
};

export const loginUser = async (payload: AuthPayload): Promise<void> => {
  Loading.show();
  await sendLoginEvent();
  firebaseAuth.signInWithEmailAndPassword(payload.email, payload.password)
    .then(() => {
      if (process.env.DEV) {
        setLocalStorage('currentUserId', firebaseAuth.currentUser?.uid);
      }
      setLoggedIn(true);
    })
    .catch((error) => {
      Loading.hide();
      showErrorMessage(error.message);
    });
};

export const logoutUser = async (): Promise<void> => firebaseAuth.signOut()
  .then(() => {
    setLoggedIn(false);
  });

export const getCurrentUserId = (): null | undefined | string => firebaseAuth.currentUser?.uid;

export const handleAuthStateChange = (router: VueRouter): void => {
  firebaseAuth.onAuthStateChanged(async (user) => {
    if (user) {
      await getUserInfo();
      setLoggedIn(true);
      if (user.uid
        && isServiceWorkerAllowed()
        && !alreadySavedFcmTokensInDb()) {
        await saveFcmTokenToDb(user.uid);
      }
      // identify the user for Rollbar.
      rollbar?.configure({
        payload: {
          person: {
            id: user.uid, // required
          },
        },
      });
      Loading.hide();
      if (router.currentRoute.path !== '/create-session') {
        router.push('/create-session');
      }
    } else {
      Loading.hide();
      if (router.currentRoute.name !== 'home') {
        router.replace('/');
      }
      setLoggedIn(false);
    }
    appLoadedState = true;
  });
};
