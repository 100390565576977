const routes = [
  {
    path: '/',
    component: () => import('layouts/Layout.vue'),
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('pages/HomePage.vue'),
      },
      {
        path: '/create-session',
        name: 'create-session',
        component: () => import('pages/CreateSessionPage.vue'),
      },
      {
        path: '/subscription',
        name: 'subscription',
        component: () => import('pages/PayPalPaymentPage.vue'),
      },
      {
        path: '/auth',
        name: 'auth',
        component: () => import('pages/AuthPage.vue'),
      },
      {
        path: '/session/:id',
        name: 'session',
        component: () => import('pages/QuestionPage.vue'),
        props: (route) => ({ id: route.params.id, reviewMode: false }),
      },
      {
        path: '/review/:id',
        name: 'review',
        component: () => import('pages/SessionReviewPage.vue'),
        props: (route) => ({ sessionId: route.params.id }),
      },
      {
        path: '/sessions',
        name: 'sessions',
        component: () => import('pages/SessionsPage.vue'),
      },
      {
        path: '/statistics',
        name: 'statistics',
        component: () => import('pages/StatisticsPage.vue'),
      },
      {
        path: '/flashcards',
        name: 'flashcards',
        component: () => import('pages/CardsPage.vue'),
      },
      {
        path: '/whats-new',
        name: 'whats-new',
        component: () => import('pages/WhatsNewPage.vue'),
      },
    ],
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '*',
    component: () => import('pages/Error404.vue'),
  },
];

export default routes;
