import { Ref, ref, UnwrapRef } from '@vue/composition-api';

export const isPwaEnabledState: Ref<UnwrapRef<boolean>> = ref<boolean>(false);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function usePwaEnabledState() {
  function setPwaEnabled(pIsPwaEnabled: boolean) {
    isPwaEnabledState.value = pIsPwaEnabled;
  }

  function isPwaEnabled() {
    return isPwaEnabledState.value;
  }

  return {
    isPwaEnabledState,
    setPwaEnabled,
    isPwaEnabled,
  };
}
