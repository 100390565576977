/**
 * We are storing resized cards images in Storage because we want
 * them to show properly without cutting off.
 *
 * @param {string} fileName
 * @return {string} resized file name
 */
export const getResizedImageFileName = (fileName) => {
  const indexOfPeriod = fileName.lastIndexOf('.');
  const fileSuffix = fileName.substring(0, indexOfPeriod);
  const extension = fileName.substring(indexOfPeriod + 1);
  return `${fileSuffix}_400x400.${extension}`;
};
