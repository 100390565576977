import Vue from 'vue';
import VueRouter from 'vue-router';

import { isUserLoggedIn } from 'src/services/auth-service';
import routes from './routes';

Vue.use(VueRouter);

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

export default function createVueRouter(/* { store, ssrContext } */) {
  const vueRouter = new VueRouter({
    scrollBehavior: () => ({
      x: 0,
      y: 0,
    }),
    routes,

    // Leave these as they are and change in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    mode: process.env.VUE_ROUTER_MODE,
    base: process.env.VUE_ROUTER_BASE,
  });
  vueRouter.beforeEach((to, from, next) => {
    if (to.name === 'home'
      || to.path === 'sitemap.xml'
      || to.path === 'robots.txt'
    ) {
      return next();
    }
    if (to.name !== 'auth' && !isUserLoggedIn()) {
      return next({ name: 'auth' });
    }
    return next();
  });
  return vueRouter;
}
