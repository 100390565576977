import { getUserEmail, getUserId, getUserIdOpt } from 'src/services/firebase-auth';
import { getDb, increment } from 'boot/firebase-base';
import { UserInfo } from 'src/types/user_info';

import { None, Option } from '@zwyssigly/functional';
import firebase from 'firebase/app';

import { collectDataFromSnapshot } from 'src/repository/repository-util';
import {
  APP_VERSION,
  LogLevel,
  ONE_DAY,
  PaypalOrder,
  SUBSCRIPTION_ORDER_CACHE_KEY,
  SubscriptionOrder,
  UNLIMITED_QUESTIONS,
  USER_SPECIFIC_QUESTION_LIMIT_NOT_SET,
  UserInfoDbRecordType,
  USERS,
} from '@morsoftware/rad-core-commons';
import { logRecordToDb, sendNotification } from 'src/services/notify-author';
import { setWithExpiry } from 'src/repository/local-storage-repository';

import { wait } from 'src/util/promise-util';
import { useSubscriptionState } from 'src/state/SubscriptionState';
import { getSubscriptionDurationInMonths, isPaywallOn } from 'src/services/remote-config-service';
import axios from 'axios';
import { logError } from 'src/services/log-service';

export const updateUser = (
  lastQuestionId: number,
  numberOfQuestions: number,
): Promise<boolean> => {
  const userId = getUserId();
  const incrementQuestionsBy = increment(numberOfQuestions);
  return getDb()
    .collection(USERS)
    .doc(userId)
    .update({
      lastQuestionId,
      questionsAttempted: incrementQuestionsBy,
      updatedDate: new Date(),
    })
    .then(() => true);
};

export const createUserRecordInDb = async (
  pUserId: string | undefined,
  pEmail: string | undefined | null,
  retryCount = 3,
): Promise<boolean> => {
  const userId = pUserId ?? getUserId();
  const email = pEmail ?? getUserEmail();
  try {
    if (retryCount === 0) {
      return Promise.resolve(false);
    }

    // this email is used in cypress test to verify
    // that retry is working!
    if (email === 'test202@gmail.com' && retryCount > 1) {
      throw new Error('Error for test. Ignore it.');
    }
    await getDb().collection(USERS)
      .doc(userId)
      .set({
        userId,
        email,
        failedQuestions: [],
        markedQuestions: [],
        skippedQuestions: [],
        lastQuestionId: -1,
        questionsAllowed: isPaywallOn() ? 50 : USER_SPECIFIC_QUESTION_LIMIT_NOT_SET,
        payPalOrders: [],
        questionsAttempted: 0,
        createdDate: new Date(),
      });
    return Promise.resolve(true);
  } catch (e) {
    await sendNotification(
      `Error creating user: ${email}`,
      `Error creating user: ${e.message}, user: ${userId}, version: ${APP_VERSION}, retry count: ${retryCount}`,
    );
    await wait(500);
    return createUserRecordInDb(userId, email, retryCount - 1);
  }
};

async function getUserRecordFromDb(
  userId: string,
): Promise<Option<firebase.firestore.QuerySnapshot>> {
  const userRecord = await getDb().collection(USERS)
    .where('userId', '==', userId)
    .get();

  return userRecord.empty ? None : Option(userRecord);
}

/**
 * Get number of questions attempted by the user
 */
export const getQuestionsAttemptedFromDb = async (): Promise<number> => {
  if (getUserId()) {
    const querySnapshot = await getDb()
      .collection(USERS)
      .where('userId', '==', getUserId())
      .get();

    if (querySnapshot.empty) {
      return 0;
    }

    let questionsAttempted = 0;
    querySnapshot.forEach((doc) => {
      questionsAttempted = doc.data().questionsAttempted;
    });
    return questionsAttempted;
  }

  return 0;
};

/**
 * Update user with subject stats.
 */
export async function updateUserWithSubjectStats(userId: string): Promise<boolean> {
  const url = process.env.QUESTION_SUBJECT_STATS_URL
    ?? 'https://radiologycored.com/createUserQuestionSubjectStatsFn';
  try {
    const axiosResponse = await axios.post(
      `${url}?userId=${userId}`,
      {},
      {
        headers: {
          Accept: 'application/json',
        },
      },
    );
    return Promise.resolve(axiosResponse.status === 200);
  } catch (e) {
    logError(e, `Error updating user with subject stats: userId: ${getUserId()}, version: ${APP_VERSION}`);
    return Promise.resolve(false);
  }
}

/**
 * When getting user info from the database,
 * refresh the cache with subscription information
 * for the user (if user is subscribed).
 */
export async function getUserInfo(): Promise<Option<UserInfo>> {
  return getUserIdOpt()
    .andThenAsync((userId) => getUserRecordFromDb(userId))
    .map((documentData) => collectDataFromSnapshot<UserInfoDbRecordType>(documentData))
    .mapAsync(async (userInfoDbRecords) => {
      const userInfo = new UserInfo(userInfoDbRecords[0]);
      if (!userInfo.subjectStats) {
        await updateUserWithSubjectStats(userInfo.userId);
      }
      const { setSubscriptionState } = useSubscriptionState();
      setSubscriptionState(
        userInfo.hasSubscribed,
        userInfo.hasSubscriptionEnded,
      );
      if (userInfo.subscription) {
        setWithExpiry(SUBSCRIPTION_ORDER_CACHE_KEY, userInfo.subscription, ONE_DAY);
      }
      return userInfo;
    });
}

/**
 * When the user successfully subscribes to our service,
 * then record it in our db.
 *
 * @param {string} userId current user's id
 * @param {string} subscription subscription related information
 */
export async function addSubscriptionToUser(
  userId: string,
  subscription: SubscriptionOrder,
): Promise<boolean> {
  // eslint-disable-next-line no-return-await
  return await getDb()
    .collection(USERS)
    .doc(userId)
    .update({
      questionsAllowed: UNLIMITED_QUESTIONS,
      subscription,
    })
    .then(() => {
      setWithExpiry(SUBSCRIPTION_ORDER_CACHE_KEY, subscription, ONE_DAY);
      return true;
    })
    .catch(async (e) => {
      await logRecordToDb(
        `Error saving subscription to the db for user: ${userId}`,
        `Error saving subscription to the db for user: ${userId}, subscription: ${subscription}. Error: ${JSON.stringify(e, null, 2)}`,
        LogLevel.ERROR,
      );
      return false;
    });
}

/**
 * When the user successfully pays for our service,
 * then record it in our db.
 *
 * @param {string} userId current user's id
 * @param {string} payPalOrder paypal order information
 */
export async function addPaypalOrderToUser(
  userId: string,
  payPalOrder: PaypalOrder,
): Promise<boolean> {
  const createdDate = new Date(payPalOrder.create_time);
  payPalOrder.expiryDateTime = new Date(
    createdDate.setMonth(createdDate.getMonth() + getSubscriptionDurationInMonths()),
  ).toISOString();
  try {
    await getDb()
      .collection(USERS)
      .doc(userId)
      .update({
        questionsAllowed: UNLIMITED_QUESTIONS,
        payPalOrders: firebase.firestore.FieldValue.arrayUnion(payPalOrder),
      });
    return true;
  } catch (e) {
    await logRecordToDb(
      `Error saving paypal order info to the db for user: ${userId}`,
      `Error saving paypal order info to the db for user: ${userId}, subscription: ${payPalOrder}. Error: ${JSON.stringify(e, null, 2)}`,
      LogLevel.ERROR,
    );
    return false;
  }
}
