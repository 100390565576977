import { Option } from '@zwyssigly/functional';
import { notifyAuthor } from 'src/services/notify-author';
import { LogLevel, Quasar } from '@morsoftware/rad-core-commons';

import { firebaseAuth } from 'boot/firebase-base';

export function getUserId(): string | undefined {
  return firebaseAuth?.currentUser?.uid;
}

export function getUserEmail(): string | null | undefined {
  return firebaseAuth?.currentUser?.email;
}

export function getUserIdOpt(): Option<string> {
  return Option(firebaseAuth?.currentUser?.uid);
}

/**
 * Sends password reset email.
 *
 * @param {string} emailAddress email address where email should be sent.
 * @param {Quasar} q quasar type
 *
 * @return true if email sent successfully, false otherwise.
 */
export function sendPasswordResetEmail(emailAddress: string, q: Quasar): Promise<boolean> {
  return firebaseAuth.sendPasswordResetEmail(emailAddress)
    .then(() => true)
    .catch(async (error) => {
      const errorMessage = `Error sending email to ${emailAddress}, ${error.message}`;
      await notifyAuthor(
        errorMessage,
        errorMessage,
        LogLevel.ERROR,
        q,
      );
      return false;
    });
}
