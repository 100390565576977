/**
 * Returns a number rounded to 2 decimal digits
 *
 * Reference: https://stackoverflow.com/questions/11832914/round-to-at-most-2-decimal-places-only-if-necessary
 * @param success
 * @param total
 */
export const roundToTwo = (
  success: number,
  total: number,
): number => (
  total === 0
    ? 0
    : Math.round(((success * 100) / total) * 100) / 100
);

/**
 * Sleep for certain amount of milliseconds
 *
 * @param ms
 */
export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
