import { getStorage, minimumFetchIntervalMillis, remoteConfig } from 'boot/firebase-base';
import {
  APP_VERSION,
  APP_VERSION_CONFIG,
  IS_PWA_ENABLED,
  LogLevel,
  MEASURE_PERFORMANCE_KEY,
  MAX_OPERATION_RETRY_TIME_SECS,
  PAYWALL_KEY,
  SUBSCRIPTION_PRICE_KEY,
  SUBSCRIPTION_PRICE,
  PRINT_DATA_SIZE,
  SEND_ANALYTICS_EVENT,
  SEND_APP_VERSION,
  SHOW_NOTIFICATIONS,
  SHOW_STATISTICS,
  IS_LOG_ROCKET_ENABLED,
  IS_CARDS_ENABLED,
  PaymentModelType,
  PAYMENT_MODE_KEY,
  MAX_QUESTIONS_ALLOWED_KEY,
  SUBSCRIPTION_TIME_KEY,
  SUBSCRIPTION_TIME_IN_MONTHS,
} from '@morsoftware/rad-core-commons';
import { createRecordInEmailsTable } from 'src/services/notify-author';
import { getUserId } from 'src/services/firebase-auth';
import { setupFCM, unregisterServiceWorkersAndClearCaches } from 'src/services/service-worker-service';
import {
  getLastRemoteConfigFetchTime,
  wasFCMSetupTried,
  setLastRemoteConfigLoadTime,
} from 'src/repository/local-storage-repository';
import { usePwaEnabledState } from 'src/state/PwaEnabledState';
import { initializeLogRocket, logInfo } from 'src/services/log-service';
import { useSubscriptionState } from 'src/state/SubscriptionState';

const { setPaywallState } = useSubscriptionState();

remoteConfig.defaultConfig = {
  [PAYWALL_KEY]: true,
  [SUBSCRIPTION_PRICE_KEY]: SUBSCRIPTION_PRICE,
  [SUBSCRIPTION_TIME_KEY]: SUBSCRIPTION_TIME_IN_MONTHS,
  [MAX_QUESTIONS_ALLOWED_KEY]: 50,
  [PAYMENT_MODE_KEY]: 'pay_once',
  [MEASURE_PERFORMANCE_KEY]: true,
  [SEND_ANALYTICS_EVENT]: true,
  [PRINT_DATA_SIZE]: false,
  [SEND_APP_VERSION]: true,
  [SHOW_STATISTICS]: true,
  [SHOW_NOTIFICATIONS]: false,
  [APP_VERSION_CONFIG]: APP_VERSION,
  [IS_PWA_ENABLED]: true,
  [IS_LOG_ROCKET_ENABLED]: true,
  [IS_CARDS_ENABLED]: true,
  [MAX_OPERATION_RETRY_TIME_SECS]: 180,
};

const getBooleanConfig = (configName: string): boolean => remoteConfig.getBoolean(configName);
const getStringConfig = (configName: string): string => remoteConfig.getString(configName);
const getNumberConfig = (configName: string): number => remoteConfig.getNumber(configName);
export const shouldMeasurePerformance = (): boolean => getBooleanConfig(MEASURE_PERFORMANCE_KEY);
export const shouldSendAnalyticsEvents = (): boolean => getBooleanConfig(SEND_ANALYTICS_EVENT);
export const shouldPrintDataSize = (): boolean => getBooleanConfig(PRINT_DATA_SIZE);
export const shouldSendAppVersion = (): boolean => getBooleanConfig(SEND_APP_VERSION);
export const showStatistics = (): boolean => getBooleanConfig(SHOW_STATISTICS);
export const isPaywallOn = (): boolean => getBooleanConfig(PAYWALL_KEY);
export const getMaxQuestionsAllowed = (): number => getNumberConfig(MAX_QUESTIONS_ALLOWED_KEY);
export const showNotifications = (): boolean => getBooleanConfig(SHOW_NOTIFICATIONS);
export const getAppVersionConfig = (): string => getStringConfig(APP_VERSION_CONFIG);
export const isPWAEnabled = (): boolean => getBooleanConfig(IS_PWA_ENABLED);
export const isLogRocketEnabled = (): boolean => getBooleanConfig(IS_LOG_ROCKET_ENABLED);
export const isCardsEnabled = (): boolean => getBooleanConfig(IS_CARDS_ENABLED);
export const getPaymentModel = (): string => getStringConfig(PAYMENT_MODE_KEY);
export const isPaymentModePayOnce = (): boolean => getPaymentModel() === PaymentModelType.PAY_ONCE;
export const isAppUpdateAvailable = (): boolean => getAppVersionConfig() !== APP_VERSION;
export const getSubscriptionPrice = (): number => getNumberConfig(SUBSCRIPTION_PRICE_KEY);
export const getMaxOperationRetryTime = (): number => getNumberConfig(MAX_OPERATION_RETRY_TIME_SECS)
  * 1000;
const { setPwaEnabled } = usePwaEnabledState();
export const getSubscriptionDurationInMonths = (): number => getNumberConfig(SUBSCRIPTION_TIME_KEY);

/**
 * Has the current time exceeded the remote config's fetch time + minimumFetchIntervalMillis
 * i.e. is it that time to get remote config?
 */
const shouldFetchFromRemoteConfig = () => new Date().getTime()
  >= getLastRemoteConfigFetchTime() + minimumFetchIntervalMillis;

/**
 * Activate previously fetched config or default config
 */
export const fetchAndActivateConfig = async (): Promise<boolean> => {
  try {
    if (!process.env.DEV && shouldFetchFromRemoteConfig()) {
      await remoteConfig.fetchAndActivate();
      setLastRemoteConfigLoadTime();
    } else {
      await remoteConfig.activate();
    }
    return true;
  } catch (e) {
    if (!process.env.DEV) {
      const message = `Error fetching/activating remote config for user: ${getUserId()}`;
      await logInfo(`${message}: ${e.message}`);
    }
    return false;
  } finally {
    setPwaEnabled(isPWAEnabled());
    setPaywallState(isPaywallOn());
    getStorage().setMaxOperationRetryTime(getMaxOperationRetryTime());
    initializeLogRocket();

    if (isPWAEnabled()) {
      if (!wasFCMSetupTried()) {
        await setupFCM();
      }
    } else {
      /*
       * Remove service workers if remote config has service worker disabled
       */
      await unregisterServiceWorkersAndClearCaches();
    }
  }
};

/**
 * Fetch config and cache it (i.e. activate it later)
 */
export const fetchConfig = async (): Promise<void> => {
  try {
    if (!process.env.DEV && shouldFetchFromRemoteConfig()) {
      await remoteConfig.fetch();
      setLastRemoteConfigLoadTime();
    }
  } catch (e) {
    if (!process.env.DEV) {
      const message = `Could not fetch remote config for user: ${getUserId()}`;
      await createRecordInEmailsTable(
        getUserId(),
        new Date().toISOString(),
        LogLevel.WARN,
        message,
        `${message}. Will try again later.: ${e.message}`,
      );
    }
  }
};
